import React from 'react'
import {Link} from 'react-router-dom';
import CommonHelper from '../../../helpers/Common'
import CmsHelper from '../../../helpers/Cms'

import ProfileOptions from './ProfileOptions'

class Profile extends React.Component {
  constructor(props) {
    super(props)

    this.id = JSON.parse(sessionStorage.getItem('userId'))
    this.mail = JSON.parse(sessionStorage.getItem('mail'))

    this.state = {
      edit: false,
      imageFile: '',
      image: '',
      firstName: '',
      lastName: '',
      mail: '',
      password: '',
      passwordRepeat: ''
    }
  }

  componentDidMount() {
    this.loadProfileInfo()
  }

  loadProfileInfo() {
    this.setState({
      imageFile: '',
      image: '',
      firstName: JSON.parse(sessionStorage.getItem('firstName')),
      lastName: JSON.parse(sessionStorage.getItem('lastName')),
      mail: this.mail,
      password: '',
      passwordRepeat: '',
      error: ''
    })
    this.loadImage()
  }

  async loadImage() {
    var user = await CmsHelper.get({
      type: 'users',
      id: this.id
    })
    if(user !== undefined) {
      var image = CmsHelper.getImageUrl({
        file: user.image.path,
        width: 148,
        height: 148
      })
      this.setState({
        image: image
      })
    }
  }

  handleToggleEdit() {
    var newState = !this.state.edit
    this.setState({
      edit: newState
    })
    if(!newState) {
      this.loadProfileInfo()
    }
  }

  handleSelectFile(e) {
    this.inputElement.click()
  }

  handleMailChange(e) {
    this.setState({
      mail: e.target.value
    })
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value
    })
  }

  handlePasswordRepeatChange(e) {
    this.setState({
      passwordRepeat: e.target.value
    })
  }

  handleChangeImage(e) {
    this.uploadImage(this.inputElement.files)
  }

  async uploadImage(files) {
    var fromData = new FormData()
    fromData.append('files[0]', files[0])
    var response = await fetch('https://pfmmedical.fp-eauthor.fp-server.com/cms/api/cockpit/addAssets?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
      method: 'POST',
      body: fromData
    }).then(res => res.json())
    this.setState({
      imageFile: response.assets[0]
    })
    var image = CmsHelper.getImageUrl({
      file: response.assets[0].path,
      width: 148,
      height: 148
    })
    this.setState({
      image: image
    })
  }

  handleSaveForm() {
    this.saveForm()
  }

  async saveForm() {
    var error = false
    if(this.state.password !== '') {
      if(this.state.password !== this.state.passwordRepeat) {
        error = true
        this.setState({
          error: 'no-match'
        })
      }
    }
    if(this.state.mail !== '') {
      if(this.state.mail !== this.mail) {
        var users = await CmsHelper.get({
          type: 'users',
          filter: {
            mail: this.state.mail
          }
        })
        if(users.length > 0) {
          error = true
          this.setState({
            error: 'taken'
          })
        }
      }
    } else {
      error = true
      this.setState({
        error: 'empty-mail'
      })
    }
    if(!error) {
      if(this.state.imageFile !== '') {
        await CmsHelper.put({
          type: 'users',
          id: this.id,
          body: JSON.stringify({
            image: this.state.imageFile
          })
        })
      }
      if(this.state.password !== '') {
        if(this.state.password === this.state.passwordRepeat) {
          await CmsHelper.put({
            type: 'users',
            id: this.id,
            body: JSON.stringify({
              password: this.state.password
            })
          })
        }
      }
      if(this.state.mail !== '') {
        if(this.state.mail !== this.mail) {
          var users = await CmsHelper.get({
            type: 'users',
            filter: {
              mail: this.state.mail
            }
          })
          if(users.length === 0) {
            await fetch('https://pfmmedical.fp-eauthor.fp-server.com/cms/api/collections/remove/tokens?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                filter: {
                  token: localStorage.getItem('token')
                }
              })
            })
            await fetch(
              'https://pfmmedical.fp-eauthor.fp-server.com/cms/api/collections/save/tokens?token=1d40fd932ae1f0b9a197e1e7f2cdac',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  data: {
                    mail: this.state.mail,
                    token: localStorage.getItem('token')
                  }
                })
              }
            )
            sessionStorage.setItem('mail', JSON.stringify(this.state.mail))
            this.mail = this.state.mail
            await CmsHelper.put({
              type: 'users',
              id: this.id,
              body: JSON.stringify({
                mail: this.state.mail
              })
            })
          }
        }
      }

      this.setState({
        edit: !this.state.edit,
        error: ''
      })
      this.loadProfileInfo()
    }
  }

  error(message) {
    return(
      <div className="error-message">{message}</div>
    )
  }

  render() {
    var readonly = {}
    if(!this.state.edit) {
      readonly.readOnly = ''
    }
    return(
      <div id="main">
        <h1>Nutzerprofil</h1>
        <div id="profile-settings" className={this.state.edit ? 'edit-settings' : ''}>
          <div className="wrapper">
            <div className="profile-image" style={{'backgroundImage': 'url(' + this.state.image + ')'}}>
              {
                this.state.edit ?
                  <>
                    <button
                      className="button edit-image highlighted round"
                      onClick={this.handleSelectFile.bind(this)}
                    >
                      <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.93 64"><path id="np_edit_453965_000000" data-name="np edit 453965 000000" className="cls-1" d="M48.2,0a6.2,6.2,0,0,0-4.4,1.8L3.8,41.9a2.39,2.39,0,0,0-.7,1.3L0,61.1A2.63,2.63,0,0,0,2,64h.8l17.9-3.1a2.09,2.09,0,0,0,1.3-.7L62.1,20.1a6.16,6.16,0,0,0,0-8.7L52.5,1.8A5.66,5.66,0,0,0,48.2,0ZM21.1,54.2,9.8,42.9,39.5,13.2,50.9,24.6ZM7.4,47.5l9.1,9.1-11,1.9ZM58.7,16.7,54.3,21,43,9.7l4.4-4.4a1.15,1.15,0,0,1,1.7,0l9.6,9.6a1.27,1.27,0,0,1,0,1.8Z" transform="translate(0.03)"/></svg>
                      </span>
                    </button>
                      <input
                        ref={input => this.inputElement = input}
                        type="file"
                        accept="image/*"
                        onChange={this.handleChangeImage.bind(this)}
                      />
                  </>
                :
                  ''
              }
            </div>
            <div className="name">{this.state.firstName + ' ' + this.state.lastName}</div>
            <div className="profile-form">
              <div className="field">
                <div className="label">E-Mail</div>
                <input
                  type="text"
                  value={this.state.mail}
                  onChange={this.handleMailChange.bind(this)}
                  {...readonly}
                />
                {
                  this.state.error === 'taken' ?
                    this.error('Die E-Mail Adresse ist bereits vergeben.')
                  :
                    ''
                }
                {
                  this.state.error === 'empty-mail' ?
                    this.error('Keine gültige E-Mail Adresse.')
                  :
                    ''
                }
              </div>
              <div className="field">
                <div className="label">{this.state.edit ? 'Neues Passwort' : 'Passwort'}</div>
                <input
                  type="password"
                  value={this.state.edit ? this.state.password : '************'}
                  onChange={this.handlePasswordChange.bind(this)}
                  {...readonly}
                />
              </div>
              {
                this.state.edit ?
                  <>
                    <div className="field">
                      <div className="label">Neues Passwort wiederholen</div>
                      <input
                        type="password"
                        value={this.state.passwordRepeat}
                        onChange={this.handlePasswordRepeatChange.bind(this)}
                      />
                      {
                        this.state.error === 'no-match' ?
                          this.error('Die Passwörter stimmen nicht überein.')
                        :
                          ''
                      }
                    </div>
                    <button
                      className="button highlighted round"
                      onClick={this.handleSaveForm.bind(this)}
                    >
                      <span className="label">Speichern</span>
                    </button>
                  </>
                :
                  ''
              }
            </div>
          </div>
          <button
            className="button edit-close highlighted"
            onClick={this.handleToggleEdit.bind(this)}
          >
            {
              this.state.edit ?
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
                    <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
                  </svg>
                </span>
              :
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.93 64"><path id="np_edit_453965_000000" data-name="np edit 453965 000000" className="cls-1" d="M48.2,0a6.2,6.2,0,0,0-4.4,1.8L3.8,41.9a2.39,2.39,0,0,0-.7,1.3L0,61.1A2.63,2.63,0,0,0,2,64h.8l17.9-3.1a2.09,2.09,0,0,0,1.3-.7L62.1,20.1a6.16,6.16,0,0,0,0-8.7L52.5,1.8A5.66,5.66,0,0,0,48.2,0ZM21.1,54.2,9.8,42.9,39.5,13.2,50.9,24.6ZM7.4,47.5l9.1,9.1-11,1.9ZM58.7,16.7,54.3,21,43,9.7l4.4-4.4a1.15,1.15,0,0,1,1.7,0l9.6,9.6a1.27,1.27,0,0,1,0,1.8Z" transform="translate(0.03)"/></svg>
                </span>
            }
          </button>
        </div>
        <ProfileOptions />
      </div>
    )
  }
}

export default Profile
