import React from 'react'
import {withRouter} from 'react-router'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.title = React.createRef()
  }

  getInputValue(name) {
    return this[name].current.value
  }

  handleAddWbt(e) {
    e.preventDefault()

    fetch(
      'https://pfmmedical.fp-eauthor.fp-server.com/cms/api/collections/save/wbts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: 'wbt_template',
            title: this.getInputValue('title'),
            created_by: JSON.parse(sessionStorage.getItem('userId')),
            content: {
              modules: []
            }
          }
        })
      }
    ).then(res => res.json()).then(
      (result) => {
        this.props.onCloseModal()
        this.props.history.push('/editor/' + result._id);
      }
    )
  }

  render() {
    return(
      <div className="modal-window">
        <div className="headline">Neue Vorlage anlegen</div>
        <div className="main">
          <form
            className="form"
            onSubmit={this.handleAddWbt.bind(this)}
          >
            <input
              ref={this.title}
              type="text"
              placeholder="Template Titel"
            />
            <button className="button highlighted round" type="submit">
              <div className="label">Erstellen</div>
            </button>
          </form>
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
